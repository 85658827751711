import React, { useEffect } from "react";
import Header from "../components/Header";
import MainLayout from "../components/MainLayout";
import EPCs from "../components/EPCs";
import Script from "../node_modules/next/script";
import { getCookie } from "../utils/handleCookies";
import $ from "jquery";
import Image from "../node_modules/next/image";
import Link from "../node_modules/next/link";

/** threejs files */
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { Vector3 } from "three";
import {
  Lensflare,
  LensflareElement,
} from "three/examples/jsm/objects/Lensflare.js";

const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
loader.setDRACOLoader(dracoLoader);

// ADDED JA
import { walletVerifyConnectedAndSave } from "../lib/walletVerify";
// import { testSC } from '../components/Multiversx';

// END ADDED JA

const Index = ({ firebaseUser, userObj, connected }) => {
  const planetRef = React.useRef < HTMLDivElement > (null);
  //console.log("Connected account: ", connected);

  // this function from readmore.js, which is now removed from project assets
  function toggleText() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var button = document.getElementById("morebutton");

    if (dots.classList.contains("hidden")) {
      dots.classList.remove("hidden"); // Show the dots
      moreText.classList.add("hidden"); // Hide the more text
      button.innerHTML = "Read More"; // change text of the button
    } else {
      dots.classList.add("hidden"); // Hide the dots
      moreText.classList.remove("hidden"); // hide the more text
      button.innerHTML = "Read Less"; // change text of the button
    }
  }

  // scroll smooth with offset based on: https://stackoverflow.com/a/56391657/2036135
  function scrollToEPC(e) {
    e.preventDefault();
    const id = "epcs";
    const yOffset = -50;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  useEffect(() => {
    $(document).ready(function () {
      // littlePlanetOwner increase min height
      // setTimeout(() => {
      //   $("#littlePlanetOwner").css("min-height", "100vh");
      //   setTimeout(() => {
      //     // decrease window width
      //     window.innerWidth = window.innerWidth - 1;
      //   }, 2000);
      // }, 1000);

      // close modal
      $(".close_icon").click(function () {
        $(this).parent().parent().fadeOut();
      });
    });

  });

  useEffect(() => {
    let animateScene = true;
    const assetDir = "/assets/";
    const width = 1000;
    const height = 800;
    const minHeight = 250;
    const rotationAmount = 0.00025;
    const ambientLightIntensity = 0.5;
    const ambientLightColor = "lightskyblue";
    const pointLightIntensity = 1.5;
    const minZoomDist = 0.7;
    const maxZoomDist = 1.3;
    const autoRotate = true;
    const planetPosition = new THREE.Vector3(0, -0.43, 0);
    const onlyRotateAndAroundCenter = true;
    const waterMetalness = 1;
    const waterRoughness = 1;
    const waterMetalMap = "";
    const waterRoughMap = "";
    const initialControlsTarget = new Vector3(0, 0, 0);
    const initCameraPosition = new Vector3(0, 0, 1);
    const useOldLittlePlanet = false;
    const pointLightPosition = new THREE.Vector3(2, 1, 3);
    pointLightPosition.subVectors(pointLightPosition, initCameraPosition);

    var scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    var renderer = new THREE.WebGLRenderer({ alpha: true });
    const ambientLight = new THREE.AmbientLight(new THREE.Color(ambientLightColor));
    const clock = new THREE.Clock();

    const { domElement } = renderer;

    const controls = new OrbitControls(camera, domElement);
    ambientLight.intensity = ambientLightIntensity;
    camera.position.set(
      initCameraPosition.x,
      initCameraPosition.y,
      initCameraPosition.z
    );
    renderer.setSize(width, height);
    renderer.gammaFactor = 2.2;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.LinearToneMapping;

    // controls.autoRotate = autoRotate;
    controls.enableDamping = true;
    controls.enablePan = !onlyRotateAndAroundCenter;

    scene.add(ambientLight);
    const pointLight = getPointLight();
    // scene.add(pointLight);
    scene.add(camera);
    camera.add(pointLight);

    planetRef.current!.appendChild(domElement);
    domElement.style.cursor = "pointer";

    const onUpdate: any[] = [];

    const cameraWorldPos = camera.getWorldPosition(new THREE.Vector3());
    const center = new THREE.Vector3();

    function handleZoomRestriction() {
      const dis = camera.getWorldPosition(cameraWorldPos).distanceTo(center);
      if (dis < minZoomDist) {
        camera.position.multiplyScalar(minZoomDist / dis);
        // console.log("Max zoom in");
      } else if (dis >maxZoomDist) {
        camera.position.multiplyScalar(maxZoomDist / dis);
        // console.log("Max zoom out");
      }
    }

    const container = new THREE.Group();

    var animate = function () {
      if (!animateScene) return;
      requestAnimationFrame(animate);
      controls.update();
      pointLight.updateMatrix();
      pointLight.updateMatrixWorld();
      const time = clock.getDelta();
      onUpdate.forEach((item) => {
        item(time);
      });
      autoRotate && container.rotateY(rotationAmount);
      onlyRotateAndAroundCenter &&
        controls.target.set(
          initialControlsTarget.x,
          initialControlsTarget.y,
          initialControlsTarget.z
        );
      handleZoomRestriction();
      renderer.render(scene, camera);
    };

    animate();

    const urls = [
      useOldLittlePlanet ? "littlePlanetOriginal.glb" : "littlePlanet.glb",
      "cars.glb",
      "littlePlanet_anim.glb",
      "positions.glb",
      "whale.glb",
    ];

    container.position.set(
      planetPosition.x,
      planetPosition.y,
      planetPosition.z
    );

    scene.add(container);

    const waterName = "prPlanet_maxS_LookdevGroom_FINALprPlanet_eau_msh";

    // positioning

    controls.target = initialControlsTarget.clone();

    async function onLoadWater(waterContainer: THREE.Group, url: string) {
      controls.target = initialControlsTarget.clone();
      // if (url === "littlePlanet.glb_old") {
      // 	waterContainer.position.setX(2);
      // }

      const loader = new RGBELoader();

      let water = waterContainer.getObjectByName(waterName);
      if (!water) return;

      let waterMesh = water as THREE.Mesh;
      const mat = waterMesh.material as THREE.MeshStandardMaterial;

      // load env texture
      loader.load(
        assetDir + "textures/venice_sunset_1k.hdr",
        (texture) => {
          mat.envMap = texture;
          scene.environment = texture;
          // mat.envMapIntensity = 1;
          texture.mapping = THREE.EquirectangularReflectionMapping;
        },
        undefined,
        () => {
          console.log("Error loading texture");
        }
      );
      mat.metalness = waterMetalness;
      mat.roughness = waterRoughness;

      const metalMaploader = new THREE.TextureLoader();
      const roughMaploader = new THREE.TextureLoader();

      waterMetalMap &&
        metalMaploader.loadAsync(waterMetalMap).then((texture) => {
          mat.metalnessMap = texture;
        });
      waterRoughMap &&
        roughMaploader.loadAsync(waterRoughMap).then((texture) => {
          mat.roughnessMap = texture;
        });
    }
    urls.forEach((url) => {
      loadAsset(
        assetDir + "nft-canvas/" + url,
        container,
        onUpdate,
        (container: THREE.Group) => onLoadWater(container, url)
      );
    });
    function getPointLight() {
      // const pointLight = new THREE.pointLight(
      // 	16767651,
      // 	pointLightIntensity
      // );
      const pointLight = new THREE.PointLight(0xff9999, pointLightIntensity);
      pointLight.castShadow = true;
      pointLight.shadow.camera.far = 20;
      // pointLight.shadow.camera.near = 0.5;
      // pointLight.shadow.camera.far = 100;
      pointLight.shadow.bias = -5e-4;
      pointLight.shadow.mapSize.width = 512;
      pointLight.shadow.mapSize.height = 512;
      pointLight.position.copy(pointLightPosition);
      pointLight.lookAt(new THREE.Vector3());

      // add helper to show directional light
      // const helper = new THREE.PointLightHelper(
      //   pointLight,
      //   5,
      //   new THREE.Color(0xff0000)
      // );
      // scene.add(helper);

      // add flare
      const loader = new THREE.TextureLoader();
      loader.load(
        assetDir + "textures/lensflare0.png",
        (texture) => {
          const lensflare = new Lensflare();
          lensflare.addElement(new LensflareElement(texture));
          pointLight.add(lensflare);
        },
        undefined,
        () => {
          console.log("Error loading texture");
        }
      );
      return pointLight;
    }
    function onResize() {
      let newWidth = Math.min(window.innerWidth, width);

      const ratio = width / height;
      const newHeight = Math.max(newWidth / ratio, minHeight);

      renderer.setSize(newWidth, newHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      planetRef.current;
    }
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      animateScene = false;
      controls.dispose();
      window.removeEventListener("resize", onResize);
      domElement.remove();
    };
  }, []);

  return (
    <div>
      <MainLayout firebaseUser={firebaseUser}>
        {/* START OF INDEX PAGE */}
        <div id="index-page">
          <section
            className="bg-gradient-to-b from-rescuablue-400 to-white  relative text-white"
            id="hero-section"
            data-pg-ia-scene='{"dbg":"true","s":"custom","s_t":"end","s_s":"start","l":[{"a":{"l":[{"t":"$header","l":[{"t":"tween","p":0,"d":0.5,"l":{"--tw-bg-opacity":0.95}}]}]},"p":"scroll"}]}'
            data-pg-collapsed
          >
            <div className="container mx-auto px-4 py-24 relative">
              <div className="flex items-center justify-center">
                {/* YOGESH! PLANET NEEDS TO GO HERE! */}
                <div ref={planetRef}></div>
                {/* <img
									src="/assets/images/nft-1-500w.png"
									alt="Hero image"
									width={500}
								/> */}
              </div>
              <div className="-mx-4 flex flex-wrap justify-center">
                <div className="px-4 w-full">
                  <h1 className="flex font-normal justify-center leading-tight mb-2 poppins-semi-bold text-5xl text-center text-rescuablue-500">
                    EMPOWER THE WORLD!
                  </h1>
                  <p className="dark:amber-400 flex justify-center leading-none max-w-1xl mb-12 poppins-regular text-amber-500 text-center text-xl tracking-tight md:text-2xl xl:text-3xl">
                    Climate finance for clean renewable energy and water
                    projects
                  </p>
                  <div className="-mx-1 flex flex-wrap items-center justify-center">
                    <div className="px-1 text-right">
                      <button
                        onClick={scrollToEPC}
                        className="bg-rescuablue-500 hover:bg-pink-900 inline-flex leading-none px-5 py-3 xl:mb-28 rounded-lg text-left text-lg text-white"
                      >
                        <span className="flex justify-center">
                          Get started
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={14}
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="#fff"
                            fillRule="evenodd"
                            className="ml-1"
                          >
                            <path
                              d="M7.7931-.207c.3905-.3907 1.0235-.3907 1.414 0l6.0001 6.0054c.3904.3909.3904 1.0245 0 1.4152L9.2071 13.219c-.3924.3793-1.0161.3739-1.4018-.0121a1.002 1.002 0 0 1-.0122-1.4031l4.2931-4.2969H.5c-.5523 0-1-.4481-1-1.0009s.4477-1.0009 1-1.0009h11.5862L7.7931 1.2083a1.002 1.002 0 0 1 0-1.4153z"
                              stroke="none"
                              fill="#fff"
                              fillRule="nonzero"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            id="floating-island"
            className="bg-white max-w-full mt-11 relative"
            data-pg-collapsed
          >
            <svg
              id="blue-curve"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 200"
              fill="rgba(84, 163, 216, var(--tw-bg-opacity))"
            >
              <g stroke="none" fillRule="nonzero">
                <path
                  d="M0 64l120-16C240 32 480 0 720 0s480 32 600 48l120 16v50.023H0z"
                  opacity=".1"
                />
                <path d="M0 84l120-16c120-16 360-48 600-48s480 32 600 48l120 16v50.023H0z" />
              </g>
              <path stroke="none" d="M0 130h1440v140H0z" />
            </svg>
            <svg
              d="blue-box"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 80"
              fill="rgba(84, 163, 216, var(--tw-bg-opacity))"
            >
              <path
                fill="rgba(84, 163, 216, var(--tw-bg-opacity))"
                stroke="none"
                d="M0 0h2880v100H0z"
              />
            </svg>
            <div
              id="hex-svg"
              className="-top-1/2 absolute content-start justify-center left-1/4 w-1/2"
            >
              <img src="/assets/images/nft-2.svg" />
            </div>
          </section>
          <section
            id="intro-text"
            className="bg-rescuablue-500 flex justify-center max-w-full"
            data-pg-collapsed
          >
            <div
              id="genesis-header"
              className="md:flex text-center md:text-left w-full justify-center"
            >
              <div className="tracking-wider poppins-bold text-white">
                THE RESCUA EARTH
              </div>
              <div className="w-full md:w-60 flex md:block justify-center">
                <svg
                  id="MINT_NFT"
                  data-name="MINT NFT"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 445 70"
                  className="px-1 w-60"
                  stroke="#ffffff"
                  strokeWidth={2}
                  troke-linecap="round"
                  strokeLinejoin="miter"
                  fill="none"
                  fillRule="evenodd"
                >
                  <path d="M91 16v12h23v15H91v12h35v15H74V1h52v15H91zM199 1v69h-18V50l-15-8-8-4v32h-17V1h17v20l16 8 7 4V1h18zm32 15v12h23v15h-23v12h35v15h-52V1h52v15h-35zm67 0v12h41v42h-58V55h40V43h-40V1h58v15h-41zm55-15h17v69h-17zm50 15v12h41v42h-58V55h40V43h-40V1h58v15h-41zM18 16v39h23V43h-8V28h26v42H1V1h58v15H18z" />
                </svg>
              </div>
              <div className="tracking-wider poppins-bold mt-auto text-white">
                EPC
              </div>
            </div>
            {/* <div id="genesis-header" className="flex">
              <div className="poppins-bold text-white">THE RESCUA.EARTH </div>
              <div className="md:block">
                <svg id="MINT_NFT" data-name="MINT NFT" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 70" className="px-1 w-60" stroke="#ffffff" strokeWidth={2} troke-linecap="round" strokeLinejoin="miter" fill="none" fillRule="evenodd">
                  <path d="M91 16v12h23v15H91v12h35v15H74V1h52v15H91zM199 1v69h-18V50l-15-8-8-4v32h-17V1h17v20l16 8 7 4V1h18zm32 15v12h23v15h-23v12h35v15h-52V1h52v15h-35zm67 0v12h41v42h-58V55h40V43h-40V1h58v15h-41zm55-15h17v69h-17zm50 15v12h41v42h-58V55h40V43h-40V1h58v15h-41zM18 16v39h23V43h-8V28h26v42H1V1h58v15H18z" />
                </svg>
              </div>
              <div className="poppins-bold text-white mt-auto"> EPC </div>
            </div> */}
          </section>
          <section
            id="main-text"
            className="bg-gradient-to-b from-rescuablue-500 py-12 text-gray-50 to-rescuablue-400"
            data-pg-collapsed
          >
            <div className="container max-w-screen-md mx-auto px-4">
              <div>
                <p className="pb-5">
                  Access to clean affordable energy is the single biggest driver
                  of economic stability. Rescua.Earth deliver fair and equitable
                  funding for renewables projects to ensure everyone gets fair
                  access to clean energy, water and air.
                </p>
                <p className="pb-5">
                  When communities, companies and organisations benefit
                  financially from stable well-governed projects, they
                  decarbonise faster and further. We provide stable finance and
                  governance to achieve long-term economic benefit and ownership
                  of stable renewable energy.
                </p>
                <div className="pb-5">
                  We believe that every person in the world is entitled to 10Kwh
                  of clean renewable energy per day. We call this the Energy
                  Daily Entitlement - the EDE. And it is the EDE which will be
                  critical to ourpath to NetZero{" "}
                  <span id="dots" className="text-amber-100">
                    ...
                  </span>
                  <div id="more" className="hidden">
                    <div className="pb-5 pt-5">
                      <h2 className="text-lg py-4 uppercase text-amber-300">
                        The Problem
                      </h2>
                      Did you know that, in the US and Northern Europe, citizens
                      use between <span className="italic">three</span> and{" "}
                      <span className="italic">eight</span> times their Energy
                      Daily Entitlement per day? At the same time, in some
                      emerging nations, citizens use less than 5% of their
                      share. If access to afforable energy is the key critical
                      driver of economic prosperity, this disparity cannot be
                      fair or just. But we cannot just build coal and oil
                      powered power stations to make up the difference.
                    </div>
                    <div className="pb-5">
                      <h2 className="text-lg py-4 uppercase text-amber-300">
                        What this means
                      </h2>
                      This means that in countries like Angola, Ethiopia and
                      Rwanda, millions of people lack access to affordable clean
                      energy for refrigeration, non-polluting cooking, reliable
                      lighting for schoolwork, access to computers - or any of
                      the other thousands of daily uses of clean affordable
                      energy we take for granted. Simply telling emerging
                      nations to clean up their energy production when they are
                      not using a tenth of the energy we use to make our lives
                      better is not a fair solution.
                    </div>
                    <div className="pb-5">
                      <h2 className="text-lg py-4 uppercase text-amber-300">
                        A Positive Achieveable Solution
                      </h2>
                      We all know wealthier nations should be reducing polluting
                      energy consumption towards a more sustainable 10Kwh per
                      person per day. But we believe in a much more positive
                      vision of the future. We should be providing enough clean
                      reliable energy for every everyone in the world to not
                      just survive, but to<span className="italic">thrive</span>
                      . It can be done. It should be done. And we will do it
                      together.
                    </div>
                    <div className="pb-5">
                      <h2 className="text-lg py-4 uppercase text-amber-300">
                        What can you do?
                      </h2>
                      The Rescua.Earth Empowerment Certificate, the EPC, is the
                      simplest way to finance clean renewable energy projects
                      around the world and help deliver global citizens&apos;
                      Energy Daily Entitlements. Rescua.Earth members can
                      purchase Empowerment Certificates in denominations
                      equivalent to USD 500, 5000 or 500,000. You can think of
                      each of these Certificates as providing the EDE for a
                      whole day for between 200 and 200,000 people. That means
                      clean renewable energy for a whole village, or even a
                      small city for a day. If you prefer, you can see it as
                      offsetting the carbon of between 25 and 25,000 people in
                      Africa for a whole year. A phenomenal achievement.
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 text-center ">
                <button
                  onClick={toggleText}
                  id="morebutton"
                  className="bg-red-600 hover:bg-red-700 duration-150 ease-in-out inline-block leading-tight px-6 py-1.5 rounded-lg text-sm transition"
                >
                  Click to read more
                </button>
              </div>
            </div>
          </section>
          <section
            id="how-to-mint"
            className="bg-gradient-to-b from-rescuablue-400 py-12 text-gray-50 to-rescuablue-300"
            data-pg-collapsed
          >
            <div className="md:flex text-center md:text-left w-full justify-center">
              <div className="tracking-wider poppins-bold">HOW TO MINT THE</div>
              <div className="w-full md:w-60 flex md:block justify-center">
                <svg
                  id="MINT_NFT"
                  data-name="MINT NFT"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 445 70"
                  className="px-1 w-60"
                  stroke="#ffffff"
                  strokeWidth={2}
                  troke-linecap="round"
                  strokeLinejoin="miter"
                  fill="none"
                  fillRule="evenodd"
                >
                  <path d="M91 16v12h23v15H91v12h35v15H74V1h52v15H91zM199 1v69h-18V50l-15-8-8-4v32h-17V1h17v20l16 8 7 4V1h18zm32 15v12h23v15h-23v12h35v15h-52V1h52v15h-35zm67 0v12h41v42h-58V55h40V43h-40V1h58v15h-41zm55-15h17v69h-17zm50 15v12h41v42h-58V55h40V43h-40V1h58v15h-41zM18 16v39h23V43h-8V28h26v42H1V1h58v15H18z" />
                </svg>
              </div>
              <div className="tracking-wider poppins-bold mt-auto">EPC</div>
            </div>
            <Link href="/faq" passHref>
              <div className="grid grid-cols-2 items-center justify-center md:flex mt-16 pb-32">
                {/* item start */}
                <div className="flex justify-center items-center">
                  <div>
                    <img
                      src="/assets/images/howtomint/mint-icon-1.svg"
                      className="w-32"
                    />
                    <div className="text-center poppins-semi-bold tracking-wider">
                      SIGN UP
                    </div>
                  </div>
                  <div className="-mt-4 h-2.5 w-32 md:-ml-5 hidden md:block bg-white" />
                  <div className="-mt-4 h-2.5 w-16 -ml-16 hidden md:block bg-gray-300" />
                </div>
                {/* item end */}
                {/* item start */}
                <div className="flex justify-center items-center md:-ml-5">
                  <div>
                    <img
                      src="/assets/images/howtomint/mint-icon-2.svg"
                      className="w-32"
                    />
                    <div className="text-center poppins-semi-bold tracking-wider">
                      SET UP WALLET
                    </div>
                  </div>
                  <div className="-mt-4 h-2.5 w-32 md:-ml-5 hidden md:block bg-white" />
                  <div className="-mt-4 h-2.5 w-16 -ml-16 hidden md:block bg-gray-300" />
                </div>
                {/* item end */}
                {/* item start */}
                <div className="flex justify-center items-center md:-ml-5">
                  <div>
                    <img
                      src="/assets/images/howtomint/mint-icon-3.svg"
                      className="w-32"
                    />
                    <div className="text-center poppins-semi-bold tracking-wider">
                      BUY E-GOLD
                    </div>
                  </div>
                  <div className="-mt-4 h-2.5 w-32 md:-ml-5 hidden md:block bg-white" />
                  <div className="-mt-4 h-2.5 w-16 -ml-16 hidden md:block bg-gray-300" />
                </div>
                {/* item start */}
                <div className="flex justify-center items-center md:-ml-5">
                  <div>
                    <img
                      src="/assets/images/howtomint/mint-icon-4.svg"
                      className="w-32"
                    />
                    <div className="text-center poppins-semi-bold tracking-wider">
                      BUY EPC
                    </div>
                  </div>
                </div>
                {/* item end */}
              </div>
            </Link>
          </section>

          <EPCs firebaseUser={firebaseUser} userObj={userObj}></EPCs>
          <section
            id="roadmap"
            className="bg-gradient-to-t from-rescuablue-500 py-12 text-gray-50 to-white"
            data-pg-collapsed
          >
            <div className="py-12 px-12 relative text-white">
              <div className="bg-rescuablue-600 container flex flex-col items-start mx-auto my-12 py-12 rounded-xl shadow-lg w-auto md:flex-row md:my-24">
                <div className="flex flex-col w-full sticky md:top-36 lg:w-1/4 mt-2 md:mt-12 px-8">
                  <p className="ml-2 text-yellow-300 uppercase tracking-loose">
                    ROADMAP
                  </p>
                  <p className="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">
                    Our goals
                  </p>
                  <p className="text-sm md:text-base text-gray-50 mb-4">
                    We have a clear mission to ensure that renewable energy,
                    water, and farming projects get the sustainable funding they
                    need. Nothing makes it onto our roadmap without being part
                    of that mission. If you want to become part of it click the
                    button below.
                  </p>
                  {/* <Link href="/dashboard" className="bg-red-600 hover:bg-red-700 duration-150 ease-in-out inline-block leading-tight px-6 py-1.5 rounded-lg text-sm transition">

                    I&apos;M READY</Link> */}
                </div>
                <div className="ml-0 lg:w-2/3 sticky">
                  <div className="container mx-auto w-full h-full">
                    <div className="relative wrap overflow-hidden lg:p-10 h-full">
                      {/* CENTRAL LINES */}
                      <div
                        className="border-2-2 border-yellow-555 absolute h-full border"
                        style={{
                          right: "50%",
                          border: "2px solid #FFC100",
                          borderRadius: "1%",
                        }}
                      />
                      <div
                        className="border-2-2 border-yellow-555 absolute h-full border"
                        style={{
                          left: "50%",
                          border: "2px solid #FFC100",
                          borderRadius: "1%",
                        }}
                      />
                      {/* ITEM 1 LEFT */}
                      <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            Q1 - Q2, 2022
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase One
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Sync Neural <br />
                              Gap Target Algorithm <br />
                              (GAP*T).
                              <br />
                              <br />
                              Give each country a roadmap to NetZero
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            COMPLETED
                          </p>
                        </div>
                      </div>
                      {/* ITEM 2 RIGHT */}
                      <div className="mb-8 flex justify-between items-center w-full right-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-left">
                          <p className="mb-3 text-base text-yellow-300">
                            Q4, 2022
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Two
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Engage with academic, scientific, economic and
                              strategic partners to define the architecture of
                              the Rescua . Earth funding platform
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            COMPLETED
                          </p>
                        </div>
                      </div>
                      {/* ITEM 3 LEFT */}
                      <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            Q2, 2023
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Three
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Development of Beta Prototype of the Rescua . Earth
                              funding platform
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            COMPLETED
                          </p>
                        </div>
                      </div>
                      {/* ITEM 4 RIGHT */}
                      <div className="mb-8 flex justify-between items-center w-full right-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4">
                          <p className="mb-3 text-base text-yellow-300">
                            Q3, 2023
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Four
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Launch of the beta prototype of the Rescua . Earth
                              Funding Platform.
                              <br />
                              <br />
                              First EPC Release (GENESIS):
                              <br />
                              &nbsp; &nbsp; Rescuan EPC - 224
                              <br />
                              &nbsp; &nbsp; Validator EPC - 22
                              <br />
                              &nbsp; &nbsp; Governor EPC - 0
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            LAUNCHED
                          </p>
                        </div>
                      </div>
                      {/* ITEM 5 LEFT */}
                      <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            Q4, 2023
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Five
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Rescua . Earth Tokenised Project Marketplace
                              Platform beta launch
                              <br />
                              <br />
                              Second EPC release (CASPIAN):
                              <br />
                              &nbsp; &nbsp; Rescuan EPC - 3,500
                              <br />
                              &nbsp; &nbsp; Validator EPC - 400
                              <br />
                              &nbsp; &nbsp; Governor EPC - 2
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            IN DEVELOPMENT
                          </p>
                        </div>
                      </div>
                      {/* ITEM 6 RIGHT */}
                      <div className="mb-8 flex justify-between items-center w-full right-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4">
                          <p className="mb-3 text-base text-yellow-300">
                            Q1, 2024
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Six
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Rescua . Earth funding platform beta launch for G20
                              Countries
                              <br />
                              <br />
                              Third EPC release (ADRIATIC):
                              <br />
                              &nbsp; &nbsp; Rescuan EPC - 5,000
                              <br />
                              &nbsp; &nbsp; Validator EPC - 500
                              <br />
                              &nbsp; &nbsp; Governor EPC - 3
                              <br />
                              <br />
                              Execution of first Energy Power as a Service
                              (ePaas) agreement in India.
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            IN PREPARATION
                          </p>
                        </div>
                      </div>
                      {/* ITEM 7 LEFT */}
                      <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            Q2, 2024
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Seven
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Rescua . Earth funding platform beta launch for
                              African Nations
                              <br />
                              <br />
                              Fourth EPC release (ATLANTIC):
                              <br />
                              &nbsp; &nbsp; Rescuan EPC - 29,899
                              <br />
                              &nbsp; &nbsp; Validator EPC - 800
                              <br />
                              &nbsp; &nbsp; Governor EPC - 5
                              <br />
                              <br />
                              Execution of first Energy Power as a Service
                              (ePaas) agreement in Hospitality Sector.
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            IN DEVELOPMENT
                          </p>
                        </div>
                      </div>
                      {/* ITEM 8 RIGHT */}
                      <div className="mb-8 flex justify-between items-center w-full right-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4">
                          <p className="mb-3 text-base text-yellow-300">
                            Q3, 2024
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Eight
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              Rescua . Earth funding platform beta launch for
                              Asian Nations
                              <br />
                              <br />
                              Execution of first Energy Power as a Service
                              (ePaas) agreement in Farming Sector.
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            IN DEVELOPMENT
                          </p>
                        </div>
                      </div>
                      {/* ITEM 9 LEFT */}
                      <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-5/12" />
                        <div className="order-1 w-5/12 px-1 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            Q4, 2024
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            Phase Nine
                          </h4>
                          <p className="text-xs md:text-base leading-snug text-gray-50 text-opacity-100">
                            <span
                              style={{
                                color:
                                  "rgb(249 250 251 / var(--tw-text-opacity))",
                                backgroundColor: "transparent",
                              }}
                            >
                              First Solar Power Plant in India.
                            </span>
                          </p>
                          <p className="text-xs md:text-base leading-snug mt-4 text-yellow-300 text-opacity-100">
                            IN DEVELOPMENT
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      className="-mt-12 min-w-0 max-w-screen-xs "
                      src="assets/images/peaceful.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* END OF INDEX PAGE */}

        <Script
          src="https://code.jquery.com/jquery-3.6.1.js"
          strategy="lazyOnload"
        />
        <Script src="/assets/script.js" strategy="lazyOnload" />
        {/* YOGESH THIS IS THE SCRIPT */}
        {/* <Script src="/assets/bundle.js" strategy="lazyOnload" /> */}
        <Script src="/assets/pgia.js" strategy="lazyOnload" />
      </MainLayout>
    </div>
  );
};

export default Index;

export async function getServerSideProps(context) {
  const retVal = await walletVerifyConnectedAndSave(context);
  console.log("Connected account server: ", JSON.stringify(retVal));
  // testSC();
  return retVal;
}

function loadAsset(
  url: string,
  scene: THREE.Group,
  onUpdate: any[],
  onLoad?: (scene: THREE.Group) => any
) {
  loader.load(
    // resource URL
    url,
    // called when the resource is loaded
    function (gltf) {
      onLoad && onLoad(gltf.scene);
      // console.log(gltf);
      scene.add(gltf.scene);

      gltf.animations; // Array<THREE.AnimationClip>
      gltf.scene; // THREE.Group
      gltf.scenes; // Array<THREE.Group>
      gltf.cameras; // Array<THREE.Camera>
      gltf.asset; // Object

      const mixer = new THREE.AnimationMixer(gltf.scene);

      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play();
      });
      onUpdate.push((time: number) => {
        mixer.update(time);
      });
      // setInterval(() => {
      // 	mixer.update(0.05);
      // }, 15);
    },
    // called while loading is progressing
    function (xhr) {
      // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function (error) {
      console.log("An error happened");
    }
  );
}
